/* eslint-disable global-require */

/**
 * DISCLAIMER
 * This file is temporary enabler of legacy _app.js getInitialProps generic approach (e.g. get all site and page data for every request)
 * It will be removed once pages are separated according to the plan described in the link below -
 * https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22497460348/Website-backend+page+API+replacement#1.-Maximum-separation
 * Track progress of the initiative via JIRA epic - https://sumupteam.atlassian.net/browse/EXPN-1003
 * Tested in src/pages-tests/_app.spec.js
 */

import get from 'lodash/fp/get';

import ErrorPage from '~/pages/_error';
import isServer from '~/shared/util/is-server';
import * as AppService from '~/shared/services/AppService';
import { getRedirectUrl } from '~/shared/services/optimizely/optimizely-redirect-experiments';
import { getOptimizelyData } from '~/shared/services/optimizely/optimizely-data';
import { VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';
import {
  fetchNinetailedPreviewData,
  isNinetailedPreviewEnabled,
  parseNinetailedExperienceQuery,
} from '~/shared/services/ninetailed/shared';
import {
  COMPUTED_PRICE_FEATURE_TOGGLE,
  NAVIGATION_REVAMP_FEATURE_TOGGLE,
} from '~/shared/services/optimizely/constants';

export async function withLegacyAppGetInitialProps(
  ctx,
  baseUrl,
  request,
  PageGetInitialProps,
) {
  const pageProps = { request, page: {} };

  const [siteRes, initialPropsRes] = await Promise.all([
    AppService.fetchSite(ctx, baseUrl).then(
      (data) => ({ data, ok: true }),
      (error) => ({ data: null, ok: false, error }),
    ),
    PageGetInitialProps(ctx, baseUrl, request).then(
      (data) => ({ data, ok: true }),
      (error) => ({ data: null, ok: false, error }),
    ),
  ]);

  const {
    activeProducts,
    products: pageProducts,
    fees: pageFees,
    cardSchemes: pageCardSchemes,
    groupedCardSchemes: pageGroupedCardSchemes,
    terms: pageTerms,
    contentType,
    id,
    redirect,
    ...page
  } = initialPropsRes.data || {};

  const isPrivate = page?.route?.private;
  if (!process.browser && isPrivate && ctx.req && ctx.res) {
    const basicAuth = require('basic-auth');
    const user = basicAuth(ctx.req);

    if (!user) {
      ctx.res.statusCode = 401;
      ctx.res.setHeader('WWW-Authenticate', `Basic realm=${ctx.req.path}`);
      return ctx.res.end();
    }

    const { CREDENTIALS } = require('../constants/auth');
    const isAuthenticated =
      user.pass === CREDENTIALS.password && user.name === CREDENTIALS.username;

    if (!isAuthenticated) {
      ctx.res.statusCode = 401;
      ctx.res.setHeader('WWW-Authenticate', `Basic realm=${ctx.req.path}`);
      return ctx.res.end();
    }
  }

  const {
    products: siteProducts,
    terms: siteTerms,
    fees: siteFees,
    cardSchemes: siteCardSchemes,
    groupedCardSchemes: siteGroupedCardSchemes,
    support,
    footnoteAsNumber,
    ...site
  } = siteRes.data || {};

  if (initialPropsRes.ok) {
    if (redirect) {
      AppService.handleRedirect(ctx, request, redirect);
      return { pageProps };
    }

    pageProps.page = page || {};
    pageProps.page.activeProducts = activeProducts || [];
    pageProps.request.id = id || '';
    pageProps.request.contentType = contentType || '';
    pageProps.page.contentType = contentType || '';
  }

  if (siteRes.ok) {
    pageProps.site = site || null;
  }

  const ntExperiencesParamStr =
    request.query[VERCEL_CDN_CACHE_PARAMS.NT_EXPERIENCES];
  if (ntExperiencesParamStr) {
    pageProps.ntExperiencesVariantsMap =
      parseNinetailedExperienceQuery(ntExperiencesParamStr) || {};
  }

  if (isNinetailedPreviewEnabled()) {
    const vercelPasswordProtectionCookie =
      AppService.getVercelPasswordProtectionCookie(ctx);
    pageProps.ninetailedPreviewData = await fetchNinetailedPreviewData(
      baseUrl,
      vercelPasswordProtectionCookie,
    );
  }

  const optimizelyData = getOptimizelyData(request.query);

  const experimentRedirectUrl = getRedirectUrl(
    optimizelyData.experiments,
    request,
  );
  if (experimentRedirectUrl) {
    AppService.handleRedirect(ctx, request, experimentRedirectUrl);
    return { pageProps };
  }

  pageProps.page.navigation =
    AppService.getNavigation(
      get('navigation', site),
      get('navigation', page),
      optimizelyData.experiments,
    ) || null;
  pageProps.page.extraLevelNavigation =
    AppService.getExtraLevelNavigation(
      get('extraLevelNavigation', page),
      optimizelyData.experiments,
    ) || null;
  pageProps.page.infoBanner =
    AppService.getInfoBanner(
      get('infoBanner', site),
      optimizelyData.experiments,
    ) || null;
  pageProps.page.secondaryLogo =
    AppService.getSecondaryLogo(
      get('secondaryLogo', page),
      optimizelyData.experiments,
    ) || null;
  pageProps.page.sections =
    AppService.getSections(get('sections', page), optimizelyData.experiments) ||
    [];
  pageProps.page.footer =
    AppService.getFooter(
      get('footer', site),
      get('footer', page),
      optimizelyData.experiments,
    ) || null;

  // NOTE: ignore new nav on these pages until new nav start to support specifics of these pages
  const ignoreNewNavPageTypes = [
    'careersPage',
    'careersPosition',
    'prospectingPage',
  ];

  const sbgPages = ['sbgIndexPage', 'sbgPost', 'sbgCategoryPage'];

  const hasNewNavigation =
    !!optimizelyData?.featureToggles[NAVIGATION_REVAMP_FEATURE_TOGGLE] &&
    !ignoreNewNavPageTypes.includes(contentType);

  const isSBGPage = sbgPages.includes(contentType);

  if (hasNewNavigation) {
    pageProps.page.headerNavigation =
      AppService.getHeaderNavigation(
        get('headerNavigation', site),
        get('tableOfContentsNavigation', page),
        get('hideInfoBanner', page),
      ) || null;

    // override table of contents for SBG pages
    if (isSBGPage) {
      pageProps.page.headerNavigation.tableOfContents = {
        id: pageProps.page.navigation.id,
        contentType: pageProps.page.navigation.contentType,
        label: pageProps.page.navigation.subSite.title,
        labelUrl: pageProps.page.navigation.subSite.url,

        items: pageProps.page.navigation.links.map((link) => ({
          id: link.id,
          contentType: link.contentType,
          label: link.label,
          destination: { url: link.url },
        })),
      };
    }
  }

  if (siteRes.ok && initialPropsRes.ok) {
    const partnerProductData = AppService.getPartnerProductData(
      site?.partnerProduct,
      ctx.asPath,
      ctx.locale,
    );
    pageProps.page.queryParams = {
      ...pageProps?.page?.queryParams,
      ...partnerProductData?.queryParams,
    };
    const mergedFees = AppService.mergeFees(
      siteFees,
      partnerProductData.fees,
      pageFees,
      optimizelyData.experiments,
    );
    const feesWithCurrency = AppService.applyCurrencyOverrideToFees(
      mergedFees,
      pageProps.page.currencyOverride,
      site.locale,
    );
    pageProps.fees = feesWithCurrency || {};
    const formattedPageProducts = AppService.getFormattedProducts(
      pageProducts,
      optimizelyData.experiments,
    );
    const formattedSiteProducts = AppService.getFormattedProducts(
      siteProducts,
      optimizelyData.experiments,
    );
    const termsOverride = AppService.getTermsOverride(
      siteTerms,
      pageTerms,
      optimizelyData.experiments,
    );
    const mergedProducts = AppService.mergeProducts(
      formattedSiteProducts,
      formattedPageProducts,
      activeProducts,
      termsOverride,
      footnoteAsNumber,
    );
    const mergedProductsWithCurrency =
      AppService.applyCurrencyOverrideToProducts(
        mergedProducts,
        pageProps.page.currencyOverride,
        site.locale,
      );

    const isComputedPriceFeatureEnabled =
      optimizelyData.featureToggles[COMPUTED_PRICE_FEATURE_TOGGLE];
    pageProps.products = isComputedPriceFeatureEnabled
      ? {
          products: AppService.applyComputedFieldsOverride(
            mergedProductsWithCurrency || {},
          ),
          siteProducts: AppService.applyComputedFieldsOverride(
            formattedSiteProducts || {},
          ),
          pageProducts: AppService.applyComputedFieldsOverride(
            formattedPageProducts || {},
          ),
        }
      : {
          products: mergedProductsWithCurrency || {},
          siteProducts: formattedSiteProducts || {},
          pageProducts: formattedPageProducts || {},
        };
    pageProps.termsOverride = termsOverride || {};
    pageProps.cardSchemes =
      AppService.getCardSchemes(
        siteCardSchemes,
        pageCardSchemes,
        optimizelyData.experiments,
      ) || [];
    pageProps.groupedCardSchemes =
      AppService.getGroupedCardSchemes(
        siteGroupedCardSchemes,
        pageGroupedCardSchemes,
        optimizelyData.experiments,
      ) || [];
    pageProps.footnoteConfig =
      AppService.getFootnoteConfig(footnoteAsNumber) || {};
  }

  if (!initialPropsRes.ok || !siteRes.ok) {
    ctx.err = initialPropsRes.error || siteRes.error;
    // override 404 status code to 410 for careers positions
    // for the old implementation
    if (
      ctx.err.statusCode === 404 &&
      ctx.asPath.startsWith('/careers/positions/')
    ) {
      ctx.err.statusCode = 410;
      ctx.err.meta = {
        isCareers: true,
      };
    }

    if (__DEV__ && isServer) {
      throw ctx.err;
    }

    const { error } = await ErrorPage.getInitialProps(ctx);
    pageProps.error = error;
    pageProps.errorRaw = JSON.stringify(ctx.err);

    // this is for lighthouse report issue on error pages
    // https://github.com/GoogleChrome/lighthouse/issues/10493
    if (ctx.query.forceSuccess === 'true') {
      ctx.res.statusCode = 200;
    }
  }

  return pageProps;
}
